import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TinyColor2 from 'tinycolor2';

import { __t } from '@mssgme/shared';
import { useBrandingUrl } from '@mssgme/hooks';
import { LinkBlank } from '@mssgme/ui';
import { MssgmeLogoIcon, ArrowRightIcon, CloseIcon } from '@mssgme/icons';

import styles from './BrandingStickyDynamic.scss';

export function BrandingStickyDynamic({ onModal, resolvedTheme = {}, isHomePage = false, ...rest }) {
    const [isBrandingClosed, setIsBrandingClosed] = useState(!isHomePage);
    const brandingUrl = useBrandingUrl({ target: 'all' });
    const stylePinned = useMemo(() => resolvedTheme.colors
        ? {
            color: resolvedTheme.colors.buttonTextColor,
            backgroundColor: resolvedTheme.colors.buttonBackground,
            boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.12)',
        }
        : {}, [resolvedTheme]);
    const styleUnpinned = useMemo(() => resolvedTheme.colors
        ? {
            border: `1px solid ${TinyColor2(resolvedTheme.colors.text).setAlpha(0.2).toHex8String()}`,
            color: resolvedTheme.colors.text,
        }
        : {}, [resolvedTheme]);
    const style = isBrandingClosed ? styleUnpinned : stylePinned;

    return (
        <>
            {!isBrandingClosed && (
                <>
                    <div className={styles.placeholder} />
                    <div className={styles.gradient} />
                </>
            )}
            <div className={cn(styles.root, isBrandingClosed && styles.userUnpinned)} {...rest}>
                <div className={styles.linkWrapper}>
                    <LinkBlank
                        className={styles.brandingLink}
                        id="userpage_branding_sticky_dynamic"
                        data-experiment="stickyDynamic"
                        href={brandingUrl}
                        style={style}
                    >
                        <MssgmeLogoIcon className={styles.logo} />
                        <span>{__t('make_for_free')}</span>
                        <ArrowRightIcon className={styles.arrowIcon} />
                    </LinkBlank>
                    {!isBrandingClosed && (
                        <div
                            className={styles.closeIconButton}
                            onClick={() => setIsBrandingClosed(true)}
                            role="button"
                        >
                            <CloseIcon
                                className={styles.closeIcon}
                                style={{ color: resolvedTheme.colors.buttonTextColor }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

BrandingStickyDynamic.propTypes = {
    resolvedTheme: PropTypes.object,
    onModal: PropTypes.func,
    isHomePage: PropTypes.bool,
};
